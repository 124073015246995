











import Vue from 'vue';
import Component from 'vue-class-component';
import { SET_LOGIN_STATUS } from '../../store/Mutations';
import { UserService } from '../../services';

@Component
export default class HandleCallback extends Vue {
  beforeCreate(): void {
    this.$auth.handleAuthentication()
      .then(() => {
        this.$auth.isAuthenticated()
          .then((result) => {
            this.$auth.getUser()
              .then((user: any) => {
                UserService.setPermission(user.sub)
                  .then(() => {
                    this.$store.commit(SET_LOGIN_STATUS, true);
                    this.$router.replace({
                      path: this.$auth.getFromUri(),
                    });
                  });
              });
          });
      }).catch((err) => {
        if (err.errorCode === 'access_denied') {
          this.$router.push('/access-denied');
        } else {
          throw err;
        }
      });
  }
}
