







import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import UserList from '@/components/security/UserList.vue';
import SecurityHeader from '@/components/security/SecurityHeader.vue';

@Component({
  components: {
    UserList,
    SecurityHeader,
  },
})
export default class SecurityView extends Vue {
}
